<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="stroke-black"
  >
    <path
      d="M19.8382 2.6089C19.3274 2.0979 18.721 1.69254 18.0535 1.41598C17.3861 1.13942 16.6707 0.99707 15.9482 0.99707C15.2257 0.99707 14.5103 1.13942 13.8428 1.41598C13.1754 1.69254 12.5689 2.0979 12.0582 2.6089L10.9982 3.6689L9.93817 2.6089C8.90647 1.5772 7.5072 0.997605 6.04817 0.997605C4.58913 0.997605 3.18986 1.5772 2.15817 2.6089C1.12647 3.64059 0.546875 5.03986 0.546875 6.4989C0.546875 7.95793 1.12647 9.3572 2.15817 10.3889L3.21817 11.4489L10.9982 19.2289L18.7782 11.4489L19.8382 10.3889C20.3492 9.87814 20.7545 9.27171 21.0311 8.60425C21.3076 7.93679 21.45 7.22138 21.45 6.4989C21.45 5.77641 21.3076 5.061 21.0311 4.39354C20.7545 3.72608 20.3492 3.11965 19.8382 2.6089Z"
      v-bind="$attrs"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
